import React from 'react';
const Dashboard = React.lazy(() => import('./pages/dashboard/dashboard'));
const UpsertAdmin = React.lazy(() =>
  import('./pages/upsert_admin_user/upsert_admin_user'),
);
const AdminUsers = React.lazy(() =>
  import('./pages/admin_users/list/admin_user_management'),
);
const Consumer = React.lazy(() => import('./pages/consumers/consumer_management'));
const UpdateConsumer = React.lazy(() =>
  import('./pages/update_consumer/update_consumer'),
);
const ActiveLoan = React.lazy(() => import('./pages/disbursed_loans/active_loans'));
const Approval = React.lazy(() => import('./pages/all_loans/all_loans'));
const UpcomingLoan = React.lazy(() => import('./pages/borrower/upcoming_repayment'));
const ViewLoan = React.lazy(() =>
  import('./pages/approval/approval_layout/approval_layout'),
);
const LoansHistory = React.lazy(() => import('./pages/loans/history/index'));
const LoanDetails = React.lazy(() => import('./pages/loans/details/index'));
const EmiConfiguration = React.lazy(() =>
  import('./pages/loan_configuration/emi_configuration'),
);
const PaydayConfiguration = React.lazy(() =>
  import('./pages/loan_configuration/payday_configuration'),
);
const BankOptions = React.lazy(() => import('./pages/bank/bank_options'));
const UpsertBankOption = React.lazy(() =>
  import('./pages/bank/bank_options/upsert'),
);
const KomjeyBankAccounts = React.lazy(() =>
  import('./pages/bank/komjey_bank_accounts'),
);
const UpsertKomjeyBankAccount = React.lazy(() =>
  import('./pages/bank/komjey_bank_accounts/upsert'),
);
const BanRecords = React.lazy(() => import('./pages/ban_records'));
const AdminAppConfiguration = React.lazy(() =>
  import('./pages/admin_app_configuration/admin_app_configuration'),
);
const UserLoans = React.lazy(() => import('./pages/loans/user_loans/index'));
const UserProfile = React.lazy(() => import('./pages/user_profile/user_profile'));

const RoutePath = {
  // dashboard
  dashboard: '/',
  // loan application
  active_loan: '/loan-application/active-loan',
  upcoming_loan: '/loan-application/active-loan/upcoming-loan',
  approval: '/loan-application/all-loans',
  loan_details: '/loan-application/all-loans/loan-detail',
  viewLoan: '/loan-application/all-loans/view-loan',

  // user authorization
  admin_user_route: '/user-authorization/admin-user',
  upsert_admin: '/user-authorization/admin-user/upsert-admin',
  consumer_route: '/user-authorization/consumers',
  update_consumer: '/user-authorization/consumers/update-consumer',
  user_loans: '/user-authorization/consumer/loans',
  ban_records: '/user-authorization/ban-records',

  // loan configuration
  loan_configuration_payday: '/loan-configuration/payday',
  loan_configuration_emi: '/loan-configuration/emi',

  // bank
  bank_options: '/bank/bank-options',
  upsert_bank_option: '/bank/bank-options/upsert',
  komjey_bank_accounts: '/bank/komjey-bank-accounts',
  upsert_komjey_bank_account: '/bank/komjey-bank-accounts/upsert',

  // app configurations
  admin_app_configuration: '/admin-app-configurations',

  // others
  user_profile: '/user-profile',
  loans_history: '/consumer/loans-history',
};

const routes = [
  {
    path: RoutePath.dashboard,
    component: Dashboard,
  },
  {
    path: RoutePath.upsert_admin + ':adminId?',
    component: UpsertAdmin,
  },
  {
    path: RoutePath.admin_user_route,
    component: AdminUsers,
  },
  {
    path: RoutePath.consumer_route,
    component: Consumer,
  },
  {
    path: RoutePath.update_consumer + ':consumerId',
    component: UpdateConsumer,
  },
  {
    path: RoutePath.active_loan,
    component: ActiveLoan,
  },
  {
    path: RoutePath.approval,
    component: Approval,
  },
  {
    path: RoutePath.upcoming_loan + ':activeLoanId',
    component: UpcomingLoan,
  },
  {
    path: RoutePath.viewLoan + ':approvalLoanId',
    component: ViewLoan,
  },
  {
    path: RoutePath.loan_configuration_emi,
    component: EmiConfiguration,
  },
  {
    path: RoutePath.loan_configuration_payday,
    component: PaydayConfiguration,
  },
  {
    path: RoutePath.bank_options,
    component: BankOptions,
  },
  {
    path: `${RoutePath.upsert_bank_option}:id?`,
    component: UpsertBankOption,
  },
  {
    path: RoutePath.komjey_bank_accounts,
    component: KomjeyBankAccounts,
  },
  {
    path: `${RoutePath.upsert_komjey_bank_account}:id?`,
    component: UpsertKomjeyBankAccount,
  },
  {
    path: `${RoutePath.loans_history}:name/:userId/:customerNumber?`,
    component: LoansHistory,
  },
  {
    path: `${RoutePath.loan_details}:loanId`,
    component: LoanDetails,
  },
  {
    path: RoutePath.ban_records,
    component: BanRecords,
  },
  {
    path: RoutePath.admin_app_configuration + '/:type',
    component: AdminAppConfiguration,
  },
  {
    path: `${RoutePath.user_loans}:name/:userId/:customerNumber?`,
    component: UserLoans,
  },
  {
    path: RoutePath.user_profile,
    component: UserProfile,
  },
];

export { RoutePath };
export default routes;
