import React, { useState, useEffect } from 'react';
import { Menu, Layout } from 'antd';
import { ThemeProvider } from 'styled-components';
import 'antd/dist/antd.css';

import Header from './header';
import routes from 'routes';
import theme from 'components/Share/theme';
import MenuData from 'config/layout_menu_data';
import useBreakPoint from 'hooks/use_break_point';
import RolesAccessControl from 'utils/roles_access_control';
import AdminUserServices from 'services/admin_user_services';
import HandleSelectedMenuItem from 'utils/handle_selected_menu_item';
import { Slider, Content, MenuItem } from 'components/Share/layout_components';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
  Link,
} from 'react-router-dom';

const KomJeyLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [availableRoles, setAvailableRoles] = useState();

  const { deviceWidth } = useBreakPoint();
  const { openedKey, selectedKey } = HandleSelectedMenuItem();

  useEffect(() => {
    (async () => {
      const response = await AdminUserServices.getCurrentAdminUser();
      if (response.ok) {
        setAvailableRoles(response.data.roles);
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    deviceWidth < 1080 ? setCollapsed(true) : setCollapsed(false);
  }, [deviceWidth]);

  if (isLoading)
    return (
      <div style={{ height: '100vh' }}>
        <LoadingIndicator />
      </div>
    );

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <Header collapsed={collapsed} onFoldClick={() => setCollapsed(!collapsed)} />
        <Layout>
          <Router>
            <Slider
              trigger={null}
              collapsible
              collapsed={collapsed}
              width="16vw"
              theme="light"
              style={{
                overflowX: 'hidden',
                height: '90vh',
              }}
            >
              <Menu
                mode="inline"
                defaultOpenKeys={[openedKey]}
                defaultSelectedKeys={[selectedKey]}
              >
                {MenuData.map((item) => {
                  if (item.subMenu.length > 0) {
                    return (
                      <Menu.SubMenu
                        key={item.key}
                        title={item.label}
                        icon={item.icon}
                      >
                        {item.subMenu.map((e) => {
                          if (
                            RolesAccessControl.rolesAccessHandler(
                              availableRoles,
                              e.permission,
                            )
                          ) {
                            return (
                              <MenuItem key={e.key}>
                                <Link to={e.route}>{e.label}</Link>
                              </MenuItem>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                      </Menu.SubMenu>
                    );
                  } else {
                    return (
                      <MenuItem key={item.key} icon={item.icon}>
                        <Link to={item.route}>{item.label}</Link>
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            </Slider>

            <Content>
              <React.Suspense fallback={<div></div>}>
                <Switch>
                  {routes.map((route, idx) => {
                    return (
                      <Route
                        path={route.path}
                        component={route.component}
                        exact
                        key={idx}
                      />
                    );
                  })}
                  <Redirect to="/" />
                </Switch>
              </React.Suspense>
            </Content>
          </Router>
        </Layout>
      </ThemeProvider>
    </Layout>
  );
};

export default KomJeyLayout;
